import React from "react";

export const Features = (props) => {
  return (
    <div id="features" className="text-center">
      <div className="container">
        {/* <div className="col-md-10 col-md-offset-1 section-title">
          <h2>Features</h2>
        </div> */}
        {props.data ? (
          props.data.map((d, i) => (
            <FeatureItem key={`${d.title}-${i}`} item={d} />
          ))
        ) : (
          "Loading..."
        )}
      </div>
    </div>
  );
};

const FeatureItem = ({ item }) => (
  <div className="d-flex align-items-center mb-3">
    <h3 className="mr-3">{item.title}</h3>
    <p>{item.text}</p>
  </div>
);


