// import React from "react";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Navigation, Pagination, EffectCoverflow, Autoplay } from "swiper/modules";
// import "swiper/css";
// import "swiper/css/navigation";
// import "swiper/css/pagination";
// import "swiper/css/scrollbar";
// import styles from "./styles.css"

// import {SliderDescription} from "./bbgDesc";

// // ... (Previous code)

// export const Slider = ({ slides }) => {
//   const handleSlideChange = (swiper) => {
//     const activeSlideIndex = swiper.activeIndex;
//     const activeSlide = slides[activeSlideIndex];
//     updateDescription(activeSlide.description);
//   };

//   const updateDescription = (description) => {
//     const descriptionElement = document.querySelector(".slider-description");
//     if (descriptionElement) {
//       descriptionElement.textContent = description;
//     }
//   };

//   return (
//     <div className="slider-container">
//       <div className="swiper-container-left-half">
//         <Swiper
//           modules={[Navigation, Pagination, EffectCoverflow, Autoplay]}
//           grabCursor={true}
//           navigation
//           centeredSlides={true}
//           coverflowEffect={{
//             rotate: 0,
//             stretch: 0,
//             depth: 100,
//             modifier: 3,
//             slideShadows: true,
//           }}
//           loop={true}
//           pagination={{ clickable: true }}
//           breakpoints={{
//             640: {
//               slidesPerView: 1,
//             },
//             768: {
//               slidesPerView: 1,
//             },
//             1024: {
//               slidesPerView: 1,
//             },
//             1560: {
//               slidesPerView: 1,
//             },
//           }}
//           onSwiper={(swiper) => {
//             updateDescription(slides[0].description); // Set initial description on mount
//             swiper.on("slideChange", () => handleSlideChange(swiper)); // Attach slideChange event
//           }}
//         >
//           {slides.map((slide) => (
//             <SwiperSlide key={slide.image}>
//               <img className="myswiper-slider" src={slide.image} alt={slide.title} />
//             </SwiperSlide>
//           ))}
//         </Swiper>
//       </div>
//       <div className="swiper-container-right-half">
//         <SliderDescription /> {/* The description will be dynamically updated */}
//       </div>
//     </div>
//   );
  
// };

import React, {useState} from 'react'
import "./styles.css"
import {BsArrowRightCircleFill, BsArrowLeftCircleFill} from "react-icons/bs";

export const Slider = ({ data }) => {
  const [slide, setSlide] = useState(0);

  const nextSlide = () => {
    setSlide(slide === data.length - 1 ? 0 : slide + 1);
  };

  const previousSlide = () => {
    setSlide(slide === 0 ? data.length - 1 : slide - 1);
  };

  return (
    <div className="carousel" id='examples'>
      <div className="carousel-header">
        <h2 className="carousel-title">MDXL Examples</h2>
      </div>
      <BsArrowLeftCircleFill className="arrow left-arrow" onClick={previousSlide} />
      <div className="carousel-content">
        <div className="slide-container">
          {data.map((item, index) => (
            <div
              key={index}
              className={slide === index ? "slide slide-visible" : "slide slide-hidden"}
            >
              <img src={item.image} alt={item.title} className="slide-image" />
              <div className="slide-text">
                <h3>{item.title}</h3>
                <p>{item.description}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="indicators">
          {data.map((_, index) => (
            <button
              key={index}
              onClick={() => setSlide(index)}
              className={slide === index ? "indicator" : "indicator indicator-inactive"}
            ></button>
          ))}
        </div>
      </div>
      <BsArrowRightCircleFill className="arrow right-arrow" onClick={nextSlide} />
    </div>
  ); 
}  